import { MOVE_ASSOCIATE, SET_MODEL, RESOURCE_STATUS_ENRICHMENT } from '../action/ShiftAllocationAction';
import { cloneDeep } from 'lodash';
import { ALLOCATION_TYPE } from '../data';

export const ShiftAllocationReducer = (state, action) => {
  switch (action.type) {
    case MOVE_ASSOCIATE:
      return moveAssociate(state, action);
    case SET_MODEL:
      // do any transformation here.
      return { ...state, ...action.payload };
    case RESOURCE_STATUS_ENRICHMENT:
      return enrichAssociates(state, action);
    default:
      return state;
  }
};

const aisleOrderFn = (assignment1, assignment2) => {
  const {
    allocationAttrs: { aisles: aisles1 },
    resourceId: associateAlias1
  } = assignment1;
  const {
    allocationAttrs: { aisles: aisles2 },
    resourceId: associateAlias2
  } = assignment2;

  if (Number(aisles1[0]) === Number(aisles2[0])) {
    return associateAlias1.localeCompare(associateAlias2);
  }

  return Number(aisles1[0]) - Number(aisles2[0]);
};

const enrichAssociates = (state, action) => {
  const stateCopy = cloneDeep(state);
  const { resourceStatuses } = action.payload;

  resourceStatuses.forEach((resourceStatus) => {
    const { punchedInStatus, resourceId, trackingProcess } = resourceStatus;
    const associate = stateCopy.associateInfo[resourceId];
    if (associate) {
      associate.clockedInStatus = punchedInStatus;
      associate.trackingProcess = trackingProcess;
    }
  });

  return stateCopy;
};

const moveAssociate = (state, action) => {
  const stateCopy = cloneDeep(state);
  const { associateAlias } = action.data;
  const { boardEntry, processPath } = action.data.to;
  const toBoardEntry = stateCopy.processInfo.find((entry) => {
    return entry.id === boardEntry;
  });
  const assignments = [...toBoardEntry.assignments];

  if (toBoardEntry.allocationType === ALLOCATION_TYPE.AISLES) {
    const { minAisle, maxAisle } = action.data.to;
    const clusterId = boardEntry.split('_')[1];
    assignments.push({
      resourceId: associateAlias,
      boardEntity: boardEntry,
      allocationAttrs: {
        process: processPath,
        cluster: clusterId,
        type: ALLOCATION_TYPE.AISLES,
        aisles: Array(maxAisle - minAisle + 1)
          .fill()
          .map((_, idx) => `${clusterId}-${minAisle + idx}`)
      }
    });
    assignments.sort(aisleOrderFn);
    toBoardEntry.assignments = assignments;
  } else {
    const assignments = toBoardEntry.assignments;
    assignments.push({
      resourceId: associateAlias,
      boardEntity: boardEntry,
      allocationAttrs: {
        process: processPath
      }
    });
    toBoardEntry.assignments = assignments;
  }
  return removeAssociate(stateCopy, action);
};

const removeAssociate = (state, action) => {
  const stateCopy = cloneDeep(state);
  const { associateAlias } = action.data;
  const { boardEntry } = action.data.from;

  const fromBoardEntry = stateCopy.processInfo.find((entry) => entry.id === boardEntry);

  const assignments = fromBoardEntry.assignments;
  const indexOfItemToRemove = assignments.findIndex((assignment) => assignment.resourceId === associateAlias);
  if (indexOfItemToRemove > -1) {
    assignments.splice(indexOfItemToRemove, 1);
  }

  return stateCopy;
};
