import { isValidDragAndDrop } from '../../utils/dragAndDropHelper';
import { MOVE_ASSOCIATE } from '../action/ShiftAllocationAction';
import { ALLOCATION_TYPE, PROCESS_PATHS } from '../data';

export const isDroppedInSingleOptionProcess = (result, boardEntries) => {
  const boardEntryId = result.destination.droppableId.split('-')[0];

  const boardEntry = boardEntries.find((entry) => entry.id === boardEntryId);
  if (isToProcessAisleAssigned(boardEntry)) {
    return false;
  }
  if (boardEntry && boardEntry.processPathEntries.length === 1) {
    return boardEntry.processPathEntries[0].process;
  } else {
    return false;
  }
};

export const isDroppedInBench = (result) => {
  const boardEntryId = result.destination.droppableId.split('-')[0];

  return boardEntryId === PROCESS_PATHS.BENCH;
};

export function moveASsociateToBench(result, provided, shiftAllocationDispatch) {
  if (isValidDragAndDrop(result, provided)) {
    const fromProcessPath = result.source.droppableId.split('-')[0];
    shiftAllocationDispatch({
      type: MOVE_ASSOCIATE,
      data: {
        associateAlias: result.draggableId,
        to: {
          boardEntry: PROCESS_PATHS.BENCH,
          processPath: PROCESS_PATHS.BENCH
        },
        from: {
          boardEntry: fromProcessPath
        }
      }
    });
  }
}

const isToProcessAisleAssigned = (toBoardEntry) => {
  return (
    toBoardEntry.allocationType === ALLOCATION_TYPE.AISLES ||
    toBoardEntry.id.includes(PROCESS_PATHS.PICK_TO_BUFFER) ||
    toBoardEntry.id.includes(PROCESS_PATHS.STOW) ||
    toBoardEntry.id.includes(PROCESS_PATHS.ADTA_STOW)
  );
};

export function moveAssociateToRecommendedSpot(result, provided, shiftAllocationDispatch) {
  if (isValidDragAndDrop(result, provided)) {
    const toProcessPath = result.destination.droppableId.split('-')[0];
    const toProcessDetailId = result.destination.droppableId.split('-')[1];
    const fromProcessPath = result.source.droppableId.split('-')[0];
    const fromProcessDetailId = result.source.droppableId.split('-')[1];
    if (
      result.destination.droppableId.includes(PROCESS_PATHS.PICK_TO_BUFFER) ||
      result.destination.droppableId.includes(PROCESS_PATHS.STOW) ||
      result.destination.droppableId.includes(PROCESS_PATHS.ADTA_STOW)
    ) {
      const minAisle = result.destination.droppableId.split('-')[2];
      const maxAisle = result.destination.droppableId.split('-')[3];
      shiftAllocationDispatch({
        type: MOVE_ASSOCIATE,
        data: {
          associateAlias: result.draggableId,
          to: {
            boardEntry: toProcessPath,
            processPath: toProcessDetailId,
            minAisle: Number(minAisle),
            maxAisle: Number(maxAisle)
          },
          from: {
            boardEntry: fromProcessPath
          }
        }
      });
    } else {
      shiftAllocationDispatch({
        type: MOVE_ASSOCIATE,
        data: {
          associateAlias: result.draggableId,
          to: {
            boardEntry: toProcessPath,
            processPath: toProcessDetailId
          },
          from: {
            boardEntry: fromProcessPath
          }
        }
      });
    }
  }
}

export const moveAssociateToSingleOptionProcess = (result, singleProcessDrop, shiftAllocationDispatch) => {
  const toProcessPath = result.destination.droppableId.split('-')[0];
  const fromProcessPath = result.source.droppableId.split('-')[0];

  shiftAllocationDispatch({
    type: MOVE_ASSOCIATE,
    data: {
      associateAlias: result.draggableId,
      to: {
        boardEntry: toProcessPath,
        processPath: singleProcessDrop
      },
      from: {
        boardEntry: fromProcessPath
      }
    }
  });
};
